<template>
  <div class="login">
    <div class="container">
      <div class="title">
        <div>{{ isChinese ? "登录" : "Sign in" }}</div>
        <img src="@/assets/images/close.png" alt="" class="close" @click="handleClose"/>
      </div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item prop="email">
          <el-input
            v-model="ruleForm.email"
            :placeholder="isChinese ? '请输入邮箱' : 'Enter your email address'"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            :placeholder="isChinese ? '请输入密码' : 'Enter your password'"
            type="password"
            show-password
          />
        </el-form-item>
        <!-- <el-form-item prop="code">
          <el-input v-model="ruleForm.code" placeholder="请输入验证码" />
        </el-form-item> -->
        <div class="forget">
          <a href="/forget">{{
          isChinese ? "忘记密码" : "Forgot your password?"
        }}</a>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{
            isChinese ? "登录" : "Sign in"
          }}</el-button>
        </el-form-item>
      </el-form>
      <div class="tips">
        <div v-if="isChinese">还没有账号？</div>
        <a href="/register" class="sign">{{
          isChinese ? "去注册" : "Not registered yet? Register now."
        }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import { Ajax, MessageTips } from "@/utils";
import { useRouter } from "vue-router";
import store from '@/store'
export default defineComponent({
  components: {},
  setup() {
    const route = useRouter()
    const ruleFormRef = ref();
    const ruleForm = reactive({
      email: "",
      password: "",
    });
    const isChinese = ref(
      // localStorage.getItem("language") === "en" ? false : true
      false
    );
    const checkEmail = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      const index = value.indexOf('@')
      console.log('-----index', index, value.slice(index + 1))
      if (index !== -1 && value.slice(index + 1).trim()) {
        callback();
      } else if (!value.length) {
        const text = isChinese.value
          ? "不能为空"
          : "This field cannot be left blank.";
        callback(new Error(text));
      } else {
        const text = isChinese.value ? "邮箱格式错误" : "E-mail error!";
        callback(new Error(text));
      }
    };

    const checkPassword = (rule, value, callback) => {
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,16}$/;
      if (reg.test(value)) {
        callback();
      } else {
        if(!value.trim().length){
          const text = isChinese.value
          ? "不能为空"
          : "This field cannot be left blank.";
        callback(new Error(text));
        } else {
            let text = isChinese.value
          ? "请输入新密码，必须包含数字、大小写字母6-16位"
          :  `The password needs to meet all the requirements below:
a total of 6-16 characters with a minimum of one uppercase letter, one lowercase letter and one number. No space and no Chinese character.`;
        callback(new Error(text));
        }
        
      }
    };
    const rules = reactive({
      email: [
        {
          trigger: "blur",
          validator: checkEmail,
        },
        // {
        //   type: 'email',
        //   message: 'E-mail error!',
        //   trigger: ['blur', 'change'],
        // },
      ],
      password: [
        {
          trigger: "blur",
          validator: checkPassword,
        },
      ]
    });

    const submitForm = (formEl) => {
      // console.log("ruleFormRef", ruleFormRef.value, formEl);
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          loginFn()
        } else {
          // console.log("error submit!");
          return false;
        }
      });
    };

    const loginFn = async () => {
      const res = await Ajax.POST({
        url: '/api/login',
        data: ruleForm
      })
      if (res.data.code === 10000){
        // localStorage.setItem('adminToken', res.data.data.token.token)
        // localStorage.setItem('adminEmail', res.data.data.token.email)
          let data=res.data.data.token;
        localStorage.setItem('adminToken', data.token)
        localStorage.setItem('adminEmail', data.email)
        localStorage.setItem('token_expired', data.token_expired)//token 过期时间
        sessionStorage.setItem('identity', data.identity)
        localStorage.setItem('year', JSON.stringify({activity_year:data.activity_year,judges_year:data.judges_year}))
        localStorage.setItem('step', 0)
        sessionStorage.setItem('judges_id', data.judges_id);//评委id
        sessionStorage.setItem('is_agree_rule', data.is_agree_rule);//评委守则是否已同意，1是 0否
        // route.push('/')
          sessionStorage.setItem('activeIndex',1);
          store.dispatch('app/setactiveIndex', '1');
          route.push('/');
          //  setTimeout(()=>{
          //     location.reload();
          //  },100)
      }
    }

    const handleClose = () => {
      route.back(-1);
    }
    return {
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      isChinese,
      loginFn,
      handleClose
    };
  },
});
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.4);
  .container {
    width: 500px;
    min-height: 507px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -253px;
    margin-left: -250px;
    background: #ffffff;
    border-radius: 8px;
    padding: 38px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 43px;
      > div {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      img {
        cursor: pointer;
      }
    }
    :deep(.el-form-item) {
      margin-bottom: 40px;
      .el-form-item__content {
        margin-left: 0 !important;
        .el-input__inner {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #eeeeee;
          padding: 0;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
        }
        .el-form-item__error {
          // font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        .el-button--primary {
          width: 100%;
          height: 60px;
          background: #e34c3e;
          border-radius: 4px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          border: none;
          margin-top: 45px;
        }
      }
    }
    .forget {
        text-align: right;
      >a{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9b9b9b;
        margin-top: 9px;
        text-decoration: none;
      }
    }
    .tips {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      .sign {
        color: #e34c3e;
        text-decoration: none;
      }
    }
  }
}
</style>
